import React from "react";
import BodyLayout from "../components/bodyLayout";
import ContactForm from "../components/contactForm";
import { Row, Col } from "antd";
import SEO from '../components/SEO'

export default ({data}) => (
  <BodyLayout color={'black'}>
  <SEO title={'Statiste - contact'} description={"Contact - email - réponse aux questions"} />
    <div className="notenoughcontent">
    <Row>
    <Col span={22} offset={1}>
      <h4 style={{paddingTop:'3rem'}}>Une question, besoin d'aide? </h4>
      <p>Si vous voulez en savoir plus, si vous avez des questions ou souhaitez un devis, n'hésitez pas à me contacter.</p>
      <p>Au plaisir de discuter avec vous.</p>
      <ContactForm/>
    </Col>
    </Row>
    </div>
  </BodyLayout>
)
