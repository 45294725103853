import React from "react";
import { Icon, Row, Col  } from 'antd';
import { useWindowDimensions } from './windowDimensionsProvider';



export default ()  => {
  const {width} = useWindowDimensions();
  const style = width < 768 ? {'margin-top':'20%','margin-bottom':'20%'} : {'margin-top':'10%', 'margin-bottom':'10%'}
return (
<div style={style}>
  <Row type="flex" justify="center" aling="middle">
    <Col span={24}>
      <h2><Icon type="mail" theme="outlined" style={{'color': 'gray', 'padding-right': '0.5rem'}} />&nbsp;b.rios@statiste.com</h2>
    </Col>
  </Row>

  <Row>
    <Col md={{span:0}} xs={{span:24}}>
    <a href="tel:+33670566876"><h2><Icon type="phone" theme="outlined" style={{'color': 'gray', 'padding-right': '0.5rem'}} />&nbsp;+33 6 70 56 68 76</h2></a>
    </Col>
    <Col md={{span:24}} xs={{span:0}}>
    <h2><Icon type="phone" theme="outlined" style={{'color': 'gray', 'padding-right': '0.5rem'}} />&nbsp;+33 6 70 56 68 76</h2>
    </Col>
  </Row>
</div>
)}
